// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-disease-js": () => import("./../../../src/templates/disease.js" /* webpackChunkName: "component---src-templates-disease-js" */),
  "component---src-templates-diseases-overview-js": () => import("./../../../src/templates/diseasesOverview.js" /* webpackChunkName: "component---src-templates-diseases-overview-js" */),
  "component---src-templates-error-js": () => import("./../../../src/templates/error.js" /* webpackChunkName: "component---src-templates-error-js" */),
  "component---src-templates-expertise-centra-js": () => import("./../../../src/templates/expertiseCentra.js" /* webpackChunkName: "component---src-templates-expertise-centra-js" */),
  "component---src-templates-general-js": () => import("./../../../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-pompe-videos-js": () => import("./../../../src/templates/pompeVideos.js" /* webpackChunkName: "component---src-templates-pompe-videos-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-symptoms-page-js": () => import("./../../../src/templates/symptomsPage.js" /* webpackChunkName: "component---src-templates-symptoms-page-js" */),
  "component---src-templates-symptomsearch-js": () => import("./../../../src/templates/symptomsearch.js" /* webpackChunkName: "component---src-templates-symptomsearch-js" */),
  "component---src-templates-trainings-js": () => import("./../../../src/templates/trainings.js" /* webpackChunkName: "component---src-templates-trainings-js" */),
  "component---src-templates-visual-search-js": () => import("./../../../src/templates/visualSearch.js" /* webpackChunkName: "component---src-templates-visual-search-js" */)
}

